"use client";
import { Card } from "../components/ui/Card";
import { UPTIME_KUMA_API } from "../lib/constants";
import { signIn, useSession } from "next-auth/react";
import React, { useEffect } from "react";

export default function Page() {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.error === "RefreshAccessTokenError") {
      signIn(); // Force sign in to hopefully resolve error
    }
  }, [session]);
  if (!session) {
    return (
      <section className={`flex`}>
        <div className="container mx-auto flex flex-col content-center items-center py-14">
          <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
              <div className="flex flex-col">
                <span className="my-4 text-lg">You are not signed in</span>
                <a
                  href={`/api/auth/signin`}
                  className="btn text-center"
                  onClick={(e) => {
                    e.preventDefault();
                    signIn();
                  }}
                >
                  Sign in
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
    );
  }
  return (
    <section className="flex">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-16">
          <h2 className="text-xs text-brand-500 tracking-widest font-medium title-font mb-1">
            Dashboard
          </h2>
          <h1 className="text-3xl md:text-2xl font-medium title-font text-gray-900">
            Choose option
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
          <Card title={"Domains"} linkText={"Domains"} link={"/domains"} description={"View Domains"} />
          <Card title={"Plugins"} linkText={"Plugins"} link={"/plugins"} description={"View Plugins"} />
          <Card title={"Servers"} linkText={"Servers"} link={"/servers"} description={"View Servers"} />
          <Card title={"Status Monitoring"} linkText={"Status Monitoring"} link={UPTIME_KUMA_API}
                description={"View Status"} target={"_blank"} />
        </div>
      </div>
    </section>
  );
}
